import React, { Component } from 'react'

import Header from '../../Components/Header/Header'
import Overmij from '../../Components/Overmij/Overmij'
import MijnProjecten from '../../Components/Mijnprojecten/Mijnprojecten'
import Contact from '../../Components/Contact/Contact'
import Footer from '../../Components/Footer/Footer'

import './Home.css';


export default class Home extends Component {
    render() {
        return (
                <main className="main">
                    <Header />
                    <Overmij />
                    <MijnProjecten />
                    <Contact />
                    <Footer />
                </main>
        )
    }
}
