import React from 'react'

import {Link} from 'react-scroll';

import './Header.css';

export default function Header() {
    return (
        <header className="header">
            <div className="header__overlay"/>         

                <article className="header__content">
                    <h2 className="header__ondertitel">Hoi, ik ben</h2>
                    
                    <section className="header__titelWrapper">
                        <h1 className="header__titel">Daniël</h1>
                        <h1 className="header__titel">van Duijvenbode</h1>
                    </section>
                    
                    <p className="header__text">Student informatica aan de hogeschool Leiden. Ik besteed graag tijd aan het ontwerpen en realiseren van websites. Daarnaast ben ik veel met hardware bezig.</p>

                    <section className="header__buttonContainer">
                    <Link to="overmij" spy={false} smooth={true} duration={1500}>
                        <button className="header__btn primary">Over mij</button>
                    </Link>
                        <a href="./CV.pdf" download><button className="header__btn ghost">Download CV</button></a>
                    </section>
                </article>
        </header>
    )
}
