import React from 'react';

import profielfoto from './overmij.png';

import './Overmij.css';

export default function Overmij() {
    return (
        <article className='overmij' id="overmij">
            <section className="overmij__contentWrapper">
                <figure className="overmij__figure">
                    <img src={profielfoto} alt="profiel foto" className="overmij__img" />
                </figure>

                <section className="overmij__content">
                    <section className="overmij__textSection">
                        <h3 className="overmij__kop">Over mij</h3>
                        <p className="overmij__tekst">Mijn naam is Daniël. Ik ben een derde jaars student aan de hogeschool Leiden waar ik de opleiding informatica volg. Als specialisatie heb ik gekozen voor interactie-technologie en dat wil zeggen dat ik me bezig houdt met vrijwel alles op het gebied van web development en verschillende onderdelen van hardware.</p>
                        <p className="overmij__tekst">In mijn vrije tijd rijd ik graag motor. Het lijkt me erg leuk om een keer een roadtrip te maken door Europa. Daarnaast ben ik een fanatieke sporter. Zo ga ik regelmatig naar de sportschool en speel ik elke zaterdagavond zaalvoetbal. Tot slot doe ik graag een drankje met vrienden.</p>
                    </section>
                    <section className="overmij__linkSection">
                        <a href="mailto:danielvanduijvenbode@hotmail.nl"><p className="overmij__tekst email">danielvanduijvenbode@hotmail.nl &rArr;</p></a>s
                    </section>
                    
                </section>

               
            </section>

        </article>
    )
}
