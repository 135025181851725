import React from 'react';
import {Routes, Route, BrowserRouter as Router} from "react-router-dom";

import Home from './Screens/Home/Home';

import './App.css';

class App extends React.Component{
  render(){
    return(
      <Router>
        <Routes>
          <Route path='/' element={<Home/>} />
        </Routes>
      </Router>
    );
  }
}

export default App;