import React from 'react';

import './Contact.css';

export default function Contact() {
    return (
        <article className="contact">
            <section className="contact__contentWrapper">

                <section className="contact__content">
                    <h3 className="contact__kop">Contact</h3>
                    <p className="contact_tekst">Denkt u dat ik wat voor u en/of uw bedrijf kan betekenen? Stuur mij dan een bericht.</p>
                </section>

                <form action="" className="contact__form">
                        <section className="contact__naam">
                            <label htmlFor="naam" className="contact__label">Naam</label>
                            <input type="text" className="contact__input" />
                        </section>

                        <section className="contact__telefoon">
                            <label htmlFor="tel" className="contact__label">Telefoonnummer</label>
                            <input type="text" className="contact__input" />
                        </section>

                        <section className="contact__email">
                            <label htmlFor="email" className="contact__label">E-mail</label>
                            <input type="email" className="contact__input"  pattern="[^@]+@[^@]+\.[a-zA-Z]{2,}"/>
                        </section>

                        <section className="contact__bedrijf">
                            <label htmlFor="bedrijf" className="contact__label">Bedrijf</label>
                            <input type="text" className="contact__input" placeholder='optioneel'/>
                        </section>

                        <section className="contact__bericht">
                            <label htmlFor="Bericht" className="contact__label">Bericht</label>
                            <textarea type="text" className="contact__input textarea" />
                        </section>

                        <section className="contact__buttonContainer">
                            <input type="submit" value="Verstuur bericht" className="contact__submit primary" />   
                        </section>                                            
                    </form>
            </section>
        </article>
    )
}
