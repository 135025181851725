import React from 'react';

import github from './github.png';
import linkedin from './linkedin.png';

import './Footer.css';

export default function Footer() {
    return (
        <footer className='footer'>
            <section className="footer__contentWrapper">
                
                <section className="footer__logoWrapper">
                    <figure className="footer__logo">
                        <img src={github} alt="Logo GitHub" className="footer__img" />
                    </figure>
                    <figure className="footer__logo">
                        <img src={linkedin} alt="Logo LinkedIn" className="footer__img" />
                    </figure>
                </section>
            </section>
        </footer>
    )
}
