import React from 'react';

import './Mijnprojecten.css';

export default function Mijnprojecten() {
    return (
        <article className="mijnProjecten">
            <section className="mijnProjecten__titelWrapper">
                <h3 className="mijnProjecten__titel">Mijn projecten</h3>
            </section>

            <ul className="mijnProjecten__list">
                <li className="mijnProjecten__listItem"></li>
                <li className="mijnProjecten__listItem"></li>
                <li className="mijnProjecten__listItem"></li>
                <li className="mijnProjecten__listItem"></li>
            </ul>
        </article>
    )
}
